import { GetFundamentalColumn } from '@/helpers/fundamentals';
import { Field, PredicateOrderSpec, PredicateType } from '@/queries/graphql-types';
import { Predicate } from '@/types/predicate';

const getComparatorString = (comparator: string): string => {
    switch (comparator) {
        case 'GT':
        case 'GTE':
            return 'min';
        case 'LT':
        case 'LTE':
            return 'max';
        default:
            return comparator;
    }
};

export const getFieldFundamental = (field: Field): string => {
    return field.toLowerCase();
};

export const getFieldFundamentalColumn = (field: Field): ReturnType<typeof GetFundamentalColumn> => {
    return GetFundamentalColumn(getFieldFundamental(field));
};

const getFieldInfo = (field: Field): [string, ReturnType<typeof GetFundamentalColumn>] => {
    const fundamentalString = getFieldFundamental(field);
    const fundamentalMapping = GetFundamentalColumn(fundamentalString);
    if (!fundamentalMapping) {
        const words = field.toLowerCase().split('_');
        const formattedLabel =
            words[0].charAt(0).toUpperCase() +
            words[0].slice(1) +
            (words.length > 1 ? ' ' + words.slice(1).join(' ') : '');
        return [formattedLabel, undefined];
    }
    return [fundamentalMapping.displayLabel, fundamentalMapping];
};

const formatOrderSpec = (orderSpec: PredicateOrderSpec): [string, string] => {
    const [orderSpecField] = getFieldInfo(orderSpec.sort.field);
    return [
        orderSpecField,
        ` sort ${orderSpec.sort.direction.toLowerCase()}${
            orderSpec.limit ? ` limit ${orderSpec.limit.value}${orderSpec.limit.type === 'PERCENT' ? '%' : ''}` : ''
        }`,
    ];
};

const removeDuplicatesKeepOrder = (strings: Array<string>): Array<string> => {
    const seen = new Set<string>();
    return strings.filter((str) => {
        if (seen.has(str)) return false;
        seen.add(str);
        return true;
    });
};
const handleFieldOrderSpec = (
    predicateString: string,
    predicateField: Field,
    orderSpec?: PredicateOrderSpec | null
): Array<string> => {
    if (!orderSpec) return [predicateString];
    const [orderSpecField, orderSpecStr] = formatOrderSpec(orderSpec);
    if (predicateField && predicateField !== orderSpec.sort.field) {
        return removeDuplicatesKeepOrder([predicateString, `${orderSpecField}:${orderSpecStr}`]);
    }
    return removeDuplicatesKeepOrder([`${predicateString}${orderSpecStr}`]);
};

const appendOrderSpec = (predicateStrings: Array<string>, orderSpec?: PredicateOrderSpec | null): Array<string> => {
    if (!orderSpec) return removeDuplicatesKeepOrder(predicateStrings);
    const [orderSpecField, orderSpecStr] = formatOrderSpec(orderSpec);
    return removeDuplicatesKeepOrder([...predicateStrings, `${orderSpecField}:${orderSpecStr}`]);
};

const joinPredicates = (predicateStrings: Array<string>): string => {
    return predicateStrings.length === 1
        ? predicateStrings[0]
        : predicateStrings.map((str) => `(${str})`).join(' AND ');
};

export const stringsFromPredicate = (predicate: Predicate | null): Array<string> => {
    if (!predicate) return [];
    const traverse = (pred: Predicate): Array<string> => {
        switch (pred.type) {
            case PredicateType.ComparisonPredicate: {
                const [field, fundamentalMapping] = getFieldInfo(pred.field);
                const value = (fundamentalMapping?.formatFunc || ((x: string) => x))(pred.value);
                return handleFieldOrderSpec(
                    `${field}: ${value} ${getComparatorString(pred.comparator)}`,
                    pred.field,
                    pred.orderSpec
                );
            }
            case PredicateType.StatisticalPredicate: {
                const [field] = getFieldInfo(pred.field);
                const base = handleFieldOrderSpec(
                    `${field}: ${pred.statistic} ${getComparatorString(pred.comparator)}`,
                    pred.field,
                    pred.orderSpec
                );
                const context = pred.context ? joinPredicates(traverse(pred.context)) : null;
                return context ? removeDuplicatesKeepOrder([...base, context]) : base;
            }
            case PredicateType.InPredicate: {
                const [field] = getFieldInfo(pred.field);
                return handleFieldOrderSpec(
                    `${field}: ${
                        Array.isArray(pred.values)
                            ? pred.values.length === 1
                                ? pred.values[0]
                                : pred.values.join(', ')
                            : ''
                    }`,
                    pred.field,
                    pred.orderSpec
                );
            }
            case PredicateType.AndPredicate: {
                if (pred.predicates.length === 1) return appendOrderSpec(traverse(pred.predicates[0]), pred.orderSpec);
                const childStrings: Array<string> = pred.predicates.map((p) => joinPredicates(traverse(p)));
                return appendOrderSpec(childStrings, pred.orderSpec);
            }
            case PredicateType.OrPredicate: {
                if (pred.predicates.length === 0) return [];
                const allSameField = pred.predicates.every(
                    (p) => 'field' in p && p.field === ('field' in pred.predicates[0] ? pred.predicates[0].field : null)
                );
                // If all predicates are InPredicates on the same field, we can combine them into a single InPredicate
                if (allSameField && pred.predicates.every((p) => p.type === PredicateType.InPredicate)) {
                    return traverse({
                        field: pred.predicates[0].field,
                        fieldAsString: '',
                        orderSpec: pred.orderSpec,
                        type: PredicateType.InPredicate,
                        values: pred.predicates.flatMap((p) => p.values),
                    });
                }
                const childStrings: Array<string> = pred.predicates.map((p) => `(${joinPredicates(traverse(p))})`);
                return appendOrderSpec([`${childStrings.join(' OR ')}`], pred.orderSpec);
            }
            case PredicateType.GlobalContextPredicate: {
                const context = traverse(pred.context);
                const globalPred = traverse(pred.predicate);
                return appendOrderSpec([...context, ...globalPred], pred.orderSpec);
            }
            case PredicateType.SortLimitPredicate: {
                return appendOrderSpec([], pred.orderSpec);
            }
            default:
                throw new Error(`Unrecognized predicate: ${JSON.stringify(pred)}`);
        }
    };
    return traverse(predicate);
};
