import { getFieldFundamentalColumn } from '@/components/analyst/predicate';
import { Heading } from '@/components/dom/text-elements';
import { Field, PredicateType } from '@/queries/graphql-types';
import { Predicate } from '@/types/predicate';

type InFilterType = {
    field: Field;
    comparator: 'IN';
    values: Array<string>;
};

type ComparisonFilterType = {
    field: Field;
    comparator: 'GT' | 'GTE' | 'LT' | 'LTE';
    value: number;
};

type StatisticalFilterType = {
    field: Field;
    comparator: 'GT' | 'GTE' | 'LT' | 'LTE';
    statistic: string;
};

// TODO: move this type to its own file, rather than in this component file, since it's reused across components
export type CoreFilterType = InFilterType | ComparisonFilterType | StatisticalFilterType;
export type CoreFiltersType = Array<CoreFilterType>;

export const coreFiltersFromAnalystFilter = (analystFilter: Predicate | null): CoreFiltersType => {
    if (!analystFilter) return [];

    const parsePredicate = (predicate: Predicate): CoreFiltersType => {
        if (predicate.type === PredicateType.ComparisonPredicate) {
            return [
                {
                    comparator: predicate.comparator,
                    field: predicate.field,
                    value: Number(predicate.value),
                },
            ];
        }
        if (predicate.type === PredicateType.StatisticalPredicate) {
            const filters: CoreFiltersType = [
                {
                    comparator: predicate.comparator,
                    field: predicate.field,
                    statistic: predicate.statistic,
                },
            ];
            if (predicate.context) {
                filters.push(...parsePredicate(predicate.context));
            }
            return filters;
        }
        if (predicate.type === PredicateType.InPredicate) {
            return [
                {
                    comparator: 'IN',
                    field: predicate.field,
                    values: predicate.values,
                },
            ];
        }
        if (predicate.type === PredicateType.AndPredicate) {
            return predicate.predicates.flatMap(parsePredicate);
        }
        if (predicate.type === PredicateType.GlobalContextPredicate) {
            return [...parsePredicate(predicate.context), ...parsePredicate(predicate.predicate)];
        }
        if (predicate.type === PredicateType.OrPredicate) {
            return predicate.predicates.flatMap(parsePredicate);
        }
        throw new Error(`Unrecognized predicate ${JSON.stringify(predicate)}`);
    };

    return parsePredicate(analystFilter);
};

const getComparatorString = (comparator: string): string => {
    switch (comparator) {
        case 'GT':
        case 'GTE':
            return 'min';
        case 'LT':
        case 'LTE':
            return 'max';
        default:
            return comparator;
    }
};

export const buildFilterStringsFromAnalystPredicate = (analystFilter: Predicate | null): Array<string> => {
    if (!analystFilter) return [];
    const traverse = (pred: Predicate): Array<string> => {
        switch (pred.type) {
            case 'ComparisonPredicate':
                return [`${pred.field.toLowerCase()}: ${pred.value} ${getComparatorString(pred.comparator)}`];
            case 'StatisticalPredicate': {
                const base = [`${pred.field.toLowerCase()}: ${pred.statistic} ${getComparatorString(pred.comparator)}`];
                return pred.context ? base.concat(traverse(pred.context)) : base;
            }
            case 'InPredicate':
                return [
                    `${pred.field.toLowerCase()}: ${
                        Array.isArray(pred.values)
                            ? pred.values.length === 1
                                ? pred.values[0]
                                : pred.values.join(', ')
                            : ''
                    }`,
                ];
            case 'AndPredicate':
            case 'OrPredicate':
                return pred.predicates.flatMap(traverse);
            case 'GlobalContextPredicate':
                return [...traverse(pred.context), ...traverse(pred.predicate)];
            default:
                throw new Error(`Unrecognized predicate: ${JSON.stringify(pred)}`);
        }
    };
    return traverse(analystFilter);
};

export const coreFiltersUniqueFieldCount = (coreFilters: CoreFiltersType) => {
    const fields = coreFilters.filter(({ field }) => !!field).map(({ field }) => field);
    const uniqueFields = new Set(fields).size;

    const unhandled = coreFilters.filter(({ field }) => !field).length;

    return uniqueFields + unhandled;
};

export const CoreFilters = ({ coreFilters }: { coreFilters: CoreFiltersType }) => {
    const comparatorString = (comparator: string) => {
        switch (comparator) {
            case 'GT':
                return 'min';
            case 'GTE':
                return 'min';
            case 'LT':
                return 'max';
            case 'LTE':
                return 'max';
            default:
                return comparator;
        }
    };

    return (
        !!coreFilters.length && (
            <section className="w-full max-w-[800px] mx-auto mb-8">
                <Heading
                    importance={5}
                    className="mb-4"
                >
                    Core Filters
                </Heading>

                <div className="flex flex-wrap">
                    {coreFilters
                        .map((coreFilter) => {
                            if (coreFilter.comparator && coreFilter.comparator === 'IN') {
                                const fundamentalMapping = getFieldFundamentalColumn(coreFilter.field);
                                const field = fundamentalMapping?.displayLabel || coreFilter.field.toLowerCase();
                                if (coreFilter.values.length === 1) {
                                    return `${field}: ${coreFilter.values[0]}`;
                                } else {
                                    return `${field}: ${coreFilter.values.join(', ')}`;
                                }
                            } else if (
                                coreFilter.comparator &&
                                ['LT', 'LTE', 'GT', 'GTE'].includes(coreFilter.comparator)
                            ) {
                                const fundamentalMapping = getFieldFundamentalColumn(coreFilter.field);
                                const field = fundamentalMapping?.displayLabel || coreFilter.field.toLowerCase();
                                const value = (fundamentalMapping?.formatFunc || ((x: string) => x))(
                                    'statistic' in coreFilter ? coreFilter.statistic : coreFilter.value
                                );
                                return `${field}: ${value} ${comparatorString(coreFilter.comparator)}`;
                            } else {
                                return '';
                            }
                        })
                        .map((text, index) => {
                            return (
                                <div
                                    key={`filter-${index}`}
                                    className="m-1 py-2 px-4 bg-analyst-light-gray brand-gray-light rounded-full whitespace-nowrap font-brand-sm"
                                >
                                    {text}
                                </div>
                            );
                        })}
                </div>
            </section>
        )
    );
};

export const coreFiltersToAnalystFilterInput = (coreFilters: CoreFiltersType) => {
    if (coreFilters.length === 0) return null;

    // Don't pass unhandled filters

    // Group filters by each field
    const filtersByField = (
        coreFilters.filter((coreFilter) => !!coreFilter.comparator) as Array<CoreFilterType>
    ).reduce((acc: { [key in Field]?: Array<CoreFilterType> }, coreFilter) => {
        const fieldFilters = acc[coreFilter.field] || [];
        return { ...acc, [coreFilter.field]: fieldFilters.concat([coreFilter]) };
    }, {});

    const filters = Object.values(filtersByField)
        .flatMap((fieldFilters) => {
            // Group filters for each field by comparator
            const filtersByComparator = fieldFilters.reduce(
                (acc: { [key: string]: Array<CoreFilterType> }, coreFilter) => {
                    const comparatorFilters = acc[coreFilter.comparator] || [];
                    return { ...acc, [coreFilter.comparator]: comparatorFilters.concat([coreFilter]) };
                },
                {}
            );

            // Dedup comparators for which we can dedup
            return Object.entries(filtersByComparator).flatMap(([comparator, comparatorFilters]) => {
                if (['LT', 'LTE'].includes(comparator)) {
                    // Keep only the *lowest* for `LT`/`LTE`
                    return (comparatorFilters as Array<ComparisonFilterType>).reduce((a, b) =>
                        a.value < b.value ? a : b
                    );
                } else if (['GT', 'GTE'].includes(comparator)) {
                    // Keep only the *highest* for `LT`/`LTE`
                    return (comparatorFilters as Array<ComparisonFilterType>).reduce((a, b) =>
                        a.value > b.value ? a : b
                    );
                } else {
                    // TODO: dedup `IN` by taking the intersection?
                    return comparatorFilters;
                }
            }, []);
        })
        .map((coreFilter) => {
            // Convert filters to GQL input format
            if (coreFilter.comparator === 'IN') {
                return { in: { field: coreFilter.field, values: coreFilter.values } };
            } else if ('statistic' in coreFilter) {
                return {
                    statistic: {
                        comparator: coreFilter.comparator,
                        field: coreFilter.field,
                        statistic: coreFilter.statistic,
                    },
                };
            } else {
                return {
                    comparison: {
                        comparator: coreFilter.comparator,
                        field: coreFilter.field,
                        value: coreFilter.value,
                    },
                };
            }
        });

    return { and: { filters } };
};
